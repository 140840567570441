<template>
  <div>
    <div class="search-box">
      <el-input
        placeholder="请输入项目名称关键字查询"
        v-model="projectTitle"
        style="width: 300px"
      >
        <el-button
          slot="append"
          @click="getDataList()"
          icon="el-icon-search"
        ></el-button>
      </el-input>
      <el-button type="primary" @click="addProject" v-if="isTeamManage"
        >新增项目</el-button
      >
    </div>
    <div class="project-list" v-loading="loading" v-if="dataList.length">
      <div class="list-item" v-for="(item, index) in dataList" :key="index">
        <div class="type-name" :class="'type-name-' + ((index % 5) + 1)">
          {{ item.projectTypeName }}
        </div>
        <div class="title">
          {{ item.projectName }}
        </div>
        <div style="width: 20%; padding-right: 20px">
          {{ item.projectSource }}
        </div>
        <div style="width: 20%">
          <div>入组进度：</div>
          <el-progress
            :percentage="item.precentScore"
            color="#409EFF"
            style="width: 70%"
          ></el-progress>
        </div>
        <div class="btns" style="width: 20%">
          <el-button type="primary" @click="toCaseEnter(item)">病例填写</el-button>
          <el-button type="" @click="toEdcProjectInfo(item)">进入查看</el-button>
        </div>
      </div>
    </div>
    <div class="no-data" v-else>
      <img src="@/assets/img/blank.png" />
      <span>暂无数据</span>
    </div>
    <el-dialog
      title="新增项目"
      :visible.sync="visible"
      width="800px"
      :close-on-click-modal="false"
    >
      <el-form
        label-width="100"
        :model="dataForm"
        :rules="dataRules"
        class="add-project-form"
        inline
        ref="form"
      >
        <el-form-item label="项目名称" prop="projectName">
          <el-input
            v-model="dataForm.projectName"
            placeholder="请输入"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item label="项目类别" prop="projectType">
          <el-select
            v-model="dataForm.projectType"
            placeholder="请选择"
            style="width: 100%"
          >
            <el-option
              v-for="(item, index) in projectTypeList"
              :key="index"
              :label="item.name"
              :value="item.value"
              >{{ item.name }}</el-option
            >
          </el-select>
        </el-form-item>
        <el-form-item label="项目来源" prop="projectSource">
          <el-input
            v-model="dataForm.projectSource"
            placeholder="请输入"
            style="width: 100%"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确定</el-button>
        <el-button @click="visible = false">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      projectTitle: "",
      visible: false,
      loading: false,
      dataList: [],
      dataForm: {
        projectName: "",
        projectType: "",
        projectSource: "",
      },
      dataRules: {
        projectName: [
          {
            required: true,
            message: "请输入项目名称",
            trigger: "blur",
          },
        ],
        projectType: [
          {
            required: true,
            message: "请选择项目类别",
            trigger: "change",
          },
        ],
        projectSource: [
          {
            required: true,
            message: "请输入项目来源",
            trigger: "blur",
          },
        ],
      },
      projectTypeList: [],
      flag: true,
      isTeamManage: false,
    };
  },
  mounted() {
    this.getDictList();
    let roleId = "";
    if (localStorage.currentTeam) {
      let currentTeam = JSON.parse(localStorage.currentTeam);
      if (currentTeam.teamsMembers && currentTeam.teamsMembers.length) {
        roleId = currentTeam.teamsMembers[0].roleIds;
      }
    } else {
      if (localStorage.userInfo) {
        roleId = JSON.parse(localStorage.userInfo).roleId;
      }
    }
    if (
      roleId == "e89325119fa411e89e43525400558291" ||
      roleId == "e89325119fa411e89e43525400557296"
    ) {
      this.isTeamManage = true;
    }
  },
  methods: {
    getDictList() {
      this.$httpAes({
        url: this.$http.adornUrl("/dict/list"),
        method: "post",
        data: {
          stringParam1: "项目类别",
        },
      }).then(({ data }) => {
        this.projectTypeList = data.data;
        this.getDataList();
      });
    },
    getDataList() {
      let _this = this;
      _this.loading = true;
      let params = {
        stringParam2: this.projectTitle,
        stringParam3: JSON.parse(localStorage.currentTeam).id,
        pageSize: 1000,
        pageNo: 1,
      };
      _this
        .$httpAes({
          url: _this.$http.adornUrl("/edcProjectManage/getEDCProjectManages"),
          method: "post",
          data: params,
        })
        .then(({ data }) => {
          if (data.status) {
            _this.loading = false;
            _this.dataList = data.data;
            _this.dataList.forEach((ele) => {
              _this.projectTypeList.forEach((chr) => {
                if (ele.projectType == chr.value) {
                  ele.projectTypeName = chr.name;
                }
                ele.sampleNum = 0;
                if (ele.edcSubCenters && ele.edcSubCenters.length) {
                  ele.edcSubCenters.forEach((chr) => {
                    ele.sampleNum = ele.sampleNum + chr.number;
                  });
                }
                ele.precentScore = 0;
                if (ele.sampleNum) {
                  ele.precentScore = Math.ceil(
                    (ele.joinNum / ele.sampleNum) * 100
                  );
                }
              });
            });
          }
        });
    },
    addProject() {
      this.visible = true;
      this.$nextTick(() => {
        this.$refs["form"].resetFields();
      });
    },
    submitForm() {
      let params = this.dataForm;
      params.teamId = JSON.parse(localStorage.currentTeam).id;
      params.status = 1;
      this.$refs.form.validate(async (valid) => {
        if (this.flag) {
          this.flag = false;
          if (valid) {
            const { data: res } = await this.$httpAes({
              url: this.$http.adornUrl("/edcProjectManage/add"),
              method: "post",
              data: params,
            });
            if (res.status) {
              this.$message({
                message: res.msg,
                type: "success",
                duration: 1500,
                onClose: () => {
                  this.flag = true;
                  this.visible = false;
                  this.getDataList();
                  this.addConfig(res.data.id,'数据收集 开启/关闭',5);
                  this.addConfig(res.data.id,'项目是否导出病例报告表',8);
                },
              });
            } else {
              this.$message.error(res.msg);
              this.flag = true;
            }
          } else {
            return false;
          }
        }
      });
    },
    toCaseEnter(item) {
      localStorage.currentProjectId = item.id;
      window.open(this.$global.dominUrl + "/devedc/#/caseEnter", "_blank");
    },
    toEdcProjectInfo(item) {
      localStorage.currentProjectId = item.id;
      window.open(this.$global.dominUrl + "/devedc/#/projectInfo", "_blank");
    },
    async addConfig(projectId,name,num) {
      let params = {
        projectId: projectId,
        configName: name,
        configNum: num,
        configStatus: 1,
      };
      const { data: res } = await this.$httpAes({
        url: this.$http.adornUrl("/edcProjectConfig/add"),
        method: "post",
        data: params,
      });
      if (res.status) {
        this.$message({
          message: params.configStatus == 1 ? "开启成功" : "关闭成功",
          type: "success",
          duration: 1500,
          onClose: () => {
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.search-box {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
}
.project-list {
  .list-item {
    padding: 30px 0px 30px 20px;
    display: flex;
    align-items: center;
    border-top: 1px solid #e6e6e6;
    div {
      display: flex;
      justify-content: center;
    }
    .type-name {
      width: 10%;
      // background: #0099ff;
      border-radius: 3px;
      // height: 30px;
      padding: 5px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      word-break: break-all;
      &.type-name-1 {
        background: #fff8ea;
        color: #ec9a02;
      }
      &.type-name-2 {
        background: #f6f1ff;
        color: #6216f7;
      }
      &.type-name-3 {
        background: #fff0fb;
        color: #f716b4;
      }
      &.type-name-4 {
        background: #edfeff;
        color: #06d8e1;
      }
      &.type-name-5 {
        background: #f2f2f2;
        color: #333;
      }
    }
    .title {
      font-weight: bold;
      font-size: 16px;
      width: 40%;
      padding: 0 40px 0 20px;
    }
    .btns {
      display: flex;
      justify-content: flex-end;
    }
  }
}
.add-project-form {
  width: 480px !important;
  margin: 0 auto;
  /deep/.el-form-item {
    width: 480px !important;
    .el-form-item__content {
      width: calc(100% - 100px);
    }
  }
}
.no-data {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
  font-size: 16px;
  color: #666;
  img {
    width: 200px;
    margin-bottom: 20px;
  }
}
</style>
